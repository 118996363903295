<template>
  <v-card elevation="2" outlined class="mx-auto">
    <base-form
      v-if="role"
      :registerBtn="true"
      :resetBtn="true"
      @submit-form="submitForm"
      :inputs="constantMaster.inputs"
      :reactiveVar="reactiveVar"
      :numberOfCols="3"
    >
    </base-form>
    <v-card-actions class="px-4">
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
    </v-card-actions>
    <base-table
      :columnName="['item.operation']"
      :headers="constantMaster.headers"
      :items="constantList"
      :items-per-page-options="[50, 100, 150, 200, -1]"
      :per-page="100"
      :search="search"
    >
      <template v-slot:[`item.operation`]="{ item }">
        <lazy>
          <div v-if="role">
            <v-btn
              v-show="!item.menteImpossibleFlag"
              class="mr-2"
              color="primary"
              x-small
              @click="editConstant(item)"
              >{{ labels.EDIT }}</v-btn
            >
            <v-btn v-show="!item.menteImpossibleFlag" color="error" x-small @click.stop="showDeleteModal(item.id)">{{
              labels.DELETE
            }}</v-btn>
          </div>
        </lazy>
      </template>
    </base-table>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import lazy from '../components/ui/Lazy.vue';
export default {
  name: 'ConstantMaster',
  components: {
    lazy,
  },
  mounted() {
    this.initialize();
  },
  async created() {
    this.role = await this.getRole({
      page: this.path.CONSTANT_MASTER,
      authority: this.user.authority,
    });
  },
  beforeDestroy() {
    this.$store.dispatch('constantMaster/resetConstantList');
  },
  data() {
    return {
      search: '',
      reactiveVar: {
        keyString: '',
        name: '',
        value1: '',
        value2: '',
        value3: '',
        value4: '',
        value5: '',
        discription: '',
        order: null,
      },
      role: false,
    };
  },
  computed: {
    ...mapGetters({
      constantList: 'constantMaster/constantList',
      constantMaster: 'defined/constantMaster',
      labels: 'defined/labels',
      messages: 'defined/messages',
      clickedConfirm: 'ui/clickedConfirm',
      mode: 'ui/mode',
      path: 'defined/pathNames',
      user: 'defined/user',
    }),
  },
  watch: {
    clickedConfirm(value) {
      if (value) {
        switch (this.mode) {
          case this.labels.REGISTER:
            this.registerConstantProcess();
            break;
          case this.labels.DELETE:
            this.deleteConstantProcess();
            break;
          case this.labels.UPDATE:
            this.updateConstantProcess();
            break;
        }
      }
    },
  },
  methods: {
    ...mapActions({
      req: 'api/callAPI',
      toggleShowModal: 'ui/toggleShowModal',
      setModalTitle: 'ui/setModalTitle',
      setModalMessage: 'ui/setModalMessage',
      setModalConfirmBtnLabel: 'ui/setModalConfirmBtnLabel',
      setUploadLoadingStatus: 'ui/setUploadLoadingStatus',
      setClickedConfirmFlag: 'ui/setClickedConfirmFlag',
      setSuccess: 'ui/setSuccess',
      setModalSuccessBtnLabel: 'ui/setModalSuccessBtnLabel',
      setMode: 'ui/setMode',
      apiRegisterConstant: 'api/registerConstant',
      apiDeleteConstant: 'api/deleteConstant',
      apiUpdateConstant: 'api/updateConstant',
      apiGetConstantList: 'api/getConstantList',
      getRole: 'common/getRole',
    }),

    async initialize() {
      // if (this.constantList.length === 0) {
      await this.getConstantList();
      // }
      this.setMode(this.labels.REGISTER);
    },

    async registerConstantProcess() {
      this.setUploadLoadingStatus(true);
      await this.registerConstant();
      this.successProcess(this.messages.REGISTER_COMPLETE);
    },

    async deleteConstantProcess() {
      this.setUploadLoadingStatus(true);
      await this.deleteConstant();
      this.successProcess(this.messages.DELETE_COMPLETE);
    },

    async updateConstantProcess() {
      this.setUploadLoadingStatus(true);
      await this.updateConstant();
      this.successProcess(this.messages.UPDATE_COMPLETE);
    },

    successProcess(message) {
      this.setUploadLoadingStatus(false);
      this.setClickedConfirmFlag(false);
      this.setSuccess(true);
      this.setModalTitle(this.labels.COMPLETE);
      this.setModalMessage(message);
      this.setModalSuccessBtnLabel(this.labels.CLOSE);
      this.getConstantList();
      this.resetForm();
      this.setMode(this.labels.REGISTER);
    },

    async registerConstant() {
      await this.apiRegisterConstant(this.reactiveVar);
    },

    async deleteConstant() {
      await this.apiDeleteConstant(this.reactiveVar);
    },

    async updateConstant() {
      const result = {};
      Object.entries(this.reactiveVar).forEach(([key, value]) => {
        result[key] = value !== '' ? value : null;
      });
      await this.apiUpdateConstant(result);
    },

    async getConstantList() {
      await this.apiGetConstantList();
    },

    editConstant(data) {
      this.reactiveVar.id = data.id;
      this.reactiveVar.keyString = data.keyString;
      this.reactiveVar.name = data.name;
      this.reactiveVar.value1 = data.value1;
      this.reactiveVar.value2 = data.value2;
      this.reactiveVar.value3 = data.value3;
      this.reactiveVar.value4 = data.value4;
      this.reactiveVar.value5 = data.value5;
      this.reactiveVar.discription = data.discription;
      this.reactiveVar.order = data.order;
      this.setMode(this.labels.UPDATE);
    },

    showDeleteModal(id) {
      this.reactiveVar.id = id;
      this.toggleShowModal();
      this.setModalTitle(this.labels.DELETE_CONFIRM);
      this.setModalMessage(this.messages.DELETE_CONFIRM);
      this.setModalConfirmBtnLabel(this.labels.DELETE);
      this.setMode(this.labels.DELETE);
    },

    resetForm() {
      this.reactiveVar.keyString = '';
      this.reactiveVar.name = '';
      this.reactiveVar.value1 = '';
      this.reactiveVar.value2 = '';
      this.reactiveVar.value3 = '';
      this.reactiveVar.value4 = '';
      this.reactiveVar.value5 = '';
      this.reactiveVar.discription = '';
      this.reactiveVar.order = null;
    },
  },
};
</script>
